.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-toggler {
  padding: 0.1rem 0.4rem;
  font-size: 1rem;
}

.navbar-toggler-icon {
  width: 0.8rem;
  height: 0.8rem;
}
.shape-selector {
  margin-bottom: 15px;
}

.shape-btn-group {
  display: flex;
  gap: 8px;
}

.shape-btn {
  width: 40px; /* Button width */
  height: 40px; /* Button height */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px; /* Rounded corners */
  padding: 5px;
  background-color: white;
  transition: all 0.3s ease;
}

.shape-btn.active {
  background-color: #007bff;
  color: white;
}

.shape-btn:hover {
  background-color: #f0f0f0;
}

.shape-btn svg {
  display: block;
  margin: 0 auto;
}




/* Button Styles */
.btn-custom {
  background-color: #635bff;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  display: inline-block;
  text-align: center;
}

.btn-custom:hover {
  background-color: #4d5bec;
}





.flex-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.inner-sidebar {
  width: calc(100% - 80px);
  margin-left: 80px
}

.iconMenu {
  position: absolute;
  height: 100%;
  background-color: #f4f7fb;
  width: 80px
}

html .topbar {
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 9;
  height: 70px;
  gap: 6px;
  width: calc(100% - 320px)
}

.topbar-dd {
  min-width: 360px!important
}

.apps-dd {
  min-width: 830px!important;
  overflow: unset!important
}

.apps-dd .mat-mdc-menu-content {
  padding: 0
}

.upgrade-bg {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%
}

.object-cover {
  object-fit: cover
}

.app-container {
  display: flex;
}

.sidebar {
  background-color: #ffffff;
  width: 250px;
  height: 100vh;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.mat-mdc-nav-list {
  padding-top: 20px;
}
.mat-mdc-list-base{
  color: #635bff
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #635bff
}
.mat-mdc-list-item {
  padding: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.menu-list-item {
  cursor: pointer;
  transition: background 0.3s ease;
}

.menu-list-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.activeMenu {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.mdc-list-item__content {
  margin-left: 10px;
}

.routeIcon {
  margin-right: 10px;
}

.sidebar-tooltip {
  position: relative;
}

/* Hide Text for Collapsed Sidebar */
.hide-menu {
  display: inline-block;
  margin-left: 10px;
}

.content {
  padding: 20px;
  flex-grow: 1;
}

.mdc-fab {
  margin: 10px;
  background-color: #4a90e2;
  color: white;
  border-radius: 50%;
}

.mdc-fab:hover {
  background-color: #357ABD;
}

.bg-primary {
  background-color: #4a90e2;
}

.text-white {
  color: white;
}
.app-container {
  display: flex;
}

.sidebar {
  width: 250px;
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 60px;
}

.main-content {
  flex-grow: 1;
  transition: margin-left 0.3s ease;
  overflow: hidden;
  position: relative;
  background-color: rgb(245, 243, 243);
}

.main-content.expanded {
  margin-left: 60px;
}

.slim-sidebar.collapsed .hide-menu {
  display: none; /* Hide menu when collapsed */
}
.svg-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none; /* Prevents text selection while dragging */
  background-color: rgb(245, 243, 243);
}

/* Optional: Add smooth transitions for pan movements */
.svg-container {
  transition: transform 0.05s linear;
}

/* Remove transition when panning */
.svg-container:active {
  transition: none;
}

.svg-container svg {
  max-width: 100%; /* Ensure it scales within the container width */
  max-height: 100%; /* Ensure it scales within the container height */
  height: auto; /* Maintain the aspect ratio */
  width: auto; /* Adjust width dynamically */
  display: block;
  preserveAspectRatio: xMidYMid meet; 
  background-color: rgb(245, 243, 243);/* Ensure aspect ratio is maintained */
}

.expanded-panel {
  max-width: 300px;
  overflow-y: auto;
  transition: all 0.3s ease;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}

.btn-toggle {
  background-color: #635bff;
  color: white;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}
.btn-toggle:hover {
  background-color: #4d5bec;
}
.active {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}
.slim-sidebar {
  height: 100vh;
  background-color: #f8f9fc;
  width: 60px;
  transition: all 0.3s ease;
  padding: 20px 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}



/* Sidebar Header */
.sidebar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.sidebar-header .logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #635bff; /* Same as active icon color */
  margin-bottom: 20px;
}

/* Sidebar icon styling */
/* Sidebar Items */
ul.sidebar-tooltip {
  width: 100%;
  padding: 0;
}

.nav-item {
  display: flex;
  justify-content: center;
  margin-bottom: 15px; /* Space between icons */
}

/* Active and Hover States */
.nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  transition: background-color 0.2s ease, color 0.2s ease;
  color: #6c757d;
  border-radius: 8px; /* Smooth rounded corners */
}


.nav-link:hover {
  background-color: rgba(92, 107, 192, 0.1); /* Subtle hover effect */
  border-radius: 8px; /* Smooth rounded corners */
}

.nav-item.active .nav-link {
  background-color: #5c6bc0; /* Active state background */
  color: white;
}

/* Icon size and alignment */
.nav-link svg {
  width: 24px;
  height: 24px;
}



.iconMenu {
  position: absolute;
  height: 100%;
  background-color: #f4f7fb;
  width: 80px
}


.object-cover {
  object-fit: cover
}

.animation-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.animation-icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.animation-icon:hover {
  background-color: #e0e0e0;
  border-color: #635bff;
  color: #635bff;
}
.animation-icon svg {
  fill: currentColor; /* Ensure that the color of the icon is correctly inherited */
  width: 36px;
  height: 36px;
}
.container {
  width: 100%; /* Ensure full width */
  padding: 16px; /* Add some padding for better spacing */
  max-width: 100%; /* Ensure no restriction on width */
}

.container, .panel-content {
  max-width: 100%; /* Make sure it takes full width */
  padding: 16px; /* Add some padding for better spacing */
  margin: 0 auto; /* Center the container */
}

.custom-range-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  background: #e9ecef; /* Slider track background */
  border-radius: 5px;
  outline: none;
  transition: background 0.3s ease-in-out;
}

.custom-range-slider:hover {
  background: #dee2e6; /* Change color on hover */
}

/* Customizing the thumb */
.custom-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #007bff; /* Thumb color */
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.custom-range-slider::-webkit-slider-thumb:hover {
  background-color: #0056b3; /* Change color on hover */
}

.custom-range-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #007bff;
  cursor: pointer;
}

.custom-range-slider::-ms-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #007bff;
  cursor: pointer;
}

/* Styling the speed label */
.speed-label {
  display: block;
  margin-top: 10px;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
}


.zoom-control {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f3f5;
  border-radius: 10px;
  padding: 10px 20px;
  max-width: 120px;
  margin: 10px auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.zoom-btn {
  background: none;
  border: none;
  font-size: 20px;
  padding: 5px;
  cursor: pointer;
}

.zoom-display {
  margin: 0 10px;
  font-size: 18px;
  color: #333;
}

.zoom-btn:focus {
  outline: none;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears on top */
}

.loading-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 10px;
}

.progress-bar-fill {
  height: 20px;
  background-color: #4caf50;
  width: 0%; /* Will be updated dynamically */
  transition: width 0.4s ease;
}
/* Button Group Styling */
.button-group {
  display: flex;
  flex-direction: column;
  gap: 12px; /* Add space between buttons */
}

/* Styled Button */
.styled-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #635bff; /* Button color */
  color: white; /* Text color */
  font-weight: 600;
  padding: 12px 24px; /* Add padding */
  border-radius: 8px; /* Rounded corners */
  border: none; /* Remove default border */
  transition: background-color 0.3s ease; /* Smooth background change on hover */
  cursor: pointer;
}

.styled-button:hover {
  background-color: #5346e8; /* Darker shade on hover */
}

.styled-button svg {
  margin-right: 8px; /* Space between icon and text */
}

.btn-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #635bff;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  transition: background-color 0.3s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 10px;
}

.btn-custom:hover {
  background-color: #4d5bec;
}

.btn-custom-icon {
  margin-right: 10px;
}
.slider-custom {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: #e0e0e0;
  outline: none;
  opacity: 0.9;
  transition: opacity 0.2s;
}

.slider-custom:hover {
  opacity: 1;
}

.slider-custom::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #635bff;
  cursor: pointer;
}

.slider-custom::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #635bff;
  cursor: pointer;
}

.speed-label {
  font-size: 16px;
  color: #635bff;
  margin-top: 10px;
  display: inline-block;
  font-weight: bold;
}
/* ErrorPage.css */
.error-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
}

.error-page-container h1 {
  font-size: 10rem;
  color: #6c757d;
}

.error-page-container p {
  font-size: 1.5rem;
  color: #6c757d;
  margin-bottom: 1.5rem;
}

.error-page-container .btn-primary {
  background-color: #6f42c1;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
}

.error-page-container .btn-primary:hover {
  background-color: #563d7c;
  color: white;
}
.mermaid-input {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
}

.mermaid-input textarea {
  resize: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
}


/* Modal Background */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 1200px;
  height: 90vh;
  overflow-y: auto;
}

/* Close Button */
.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Categories */
.category-filter button {
  margin-right: 10px;
  padding: 5px 10px;
}

/* Template Grid */
.templates-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.template-item {
  position: relative;
}

.template-thumbnail {
  width: 100%;
  height: auto;
}

.template-actions {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
}

.preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-modal img {
  max-width: 80%;
  max-height: 80%;
}

.sidebar-divider {
  height: .5px;
  display: block;
  margin: 12px 0;
  background: #e0e6eb;
  width: 100%;
}

.sidebar-list.mdc-list .menu-list-item.activeMenu {
  box-shadow: 0 17px 20px -8px #4d5bec3b;
}

/* Burger icon - visible only on mobile */
@media (max-width: 768px) {
  .burger-icon {
    display: block; /* Ensures the icon displays on mobile */
    position: fixed;
    top: 10px;
    left: 10px; /* Adjust as needed */
    font-size: 24px;
    cursor: pointer;
    z-index: 1000; /* Ensures it’s on top of other elements */
    color: #333; /* Visible color for contrast */
  }
  
  /* Sidebar hidden by default on mobile */
  .slim-sidebar {
    transform: translateX(-100%); /* Hide sidebar by default on mobile */
    width: 250px; /* Wider sidebar on mobile */
    background-color: #f8f9fc;
  }
  
  .slim-sidebar.active {
    transform: translateX(0); /* Show sidebar when active */
  }

  /* Show text labels on mobile */
  .slim-sidebar .hide-menu {
    display: inline-block !important;
    margin-left: 10px;
  }

  /* Adjust nav items for mobile */
  .slim-sidebar .nav-item {
    width: 100%;
  }

  .slim-sidebar .nav-link {
    justify-content: flex-start;
    padding-left: 20px;
  }

  /* Add overlay when sidebar is active */
  .sidebar-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .sidebar-overlay.active {
    display: block;
  }
}

/* Hide burger icon on desktop */
.burger-icon {
  display: none; /* Hidden on larger screens */
}

/* Desktop styles */
@media (min-width: 769px) {
  .slim-sidebar {
    transform: translateX(0); /* Always show on desktop */
    width: 60px;
  }

  .slim-sidebar .hide-menu {
    display: none; /* Hide text on desktop */
  }

  .slim-sidebar .nav-item {
    width: auto;
  }

  .slim-sidebar .nav-link {
    justify-content: center;
    padding: 10px;
  }

  /* Center icons in desktop view */
  .nav-item {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px; /* Fixed width for icon container */
    height: 40px; /* Fixed height for icon container */
    margin: 5px 0;
    border-radius: 8px;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .slim-sidebar {
    transform: translateX(-100%);
    width: 250px;
    background-color: #f8f9fc;
  }

  .slim-sidebar.active {
    transform: translateX(0);
  }

  .slim-sidebar .hide-menu {
    display: inline-block !important;
    margin-left: 10px;
  }

  .slim-sidebar .nav-item {
    width: 100%;
  }

  .slim-sidebar .nav-link {
    justify-content: flex-start;
    padding-left: 20px;
    width: 100%;
  }

  .sidebar-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .sidebar-overlay.active {
    display: block;
  }
}

/* Common styles for both mobile and desktop */
.nav-link {
  color: #6c757d;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.nav-link:hover {
  background-color: rgba(92, 107, 192, 0.1);
}

.nav-item.active .nav-link {
  background-color: #5c6bc0;
  color: white;
}

/* Icon sizing */
.nav-link svg {
  width: 24px;
  height: 24px;
  min-width: 24px; /* Prevent icon shrinking */
}

/* Mobile styles for expanded panel */
@media (max-width: 768px) {
  .expanded-panel {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    transform: translateX(100%);
    z-index: 1001;
  }

  .expanded-panel.active {
    transform: translateX(0);
  }

  /* Toggle button for expanded panel */
  .panel-toggle {
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 1002;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  /* Overlay for expanded panel */
  .panel-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  .panel-overlay.active {
    display: block;
  }
}

